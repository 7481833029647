import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import overlay from './images/overlay_large.png';
import { applyAccentColorFromStorage } from './utils/theme';

const EscapeQuestion = () => {
  const navigate = useNavigate();

  useEffect(() => {
    applyAccentColorFromStorage(); // Apply purple if set
  }, []);

  const handleOptionClick = (option) => {
    if (option === 'Yes') {
      navigate('/password');
    } else if (option === 'No') {
      window.location.href = '/no';
    }
  };

  return (
    <div className='password-container'>
      <img
        src={overlay}
        alt="Overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          mixBlendMode: 'darken',
          pointerEvents: 'none',
          zIndex: 9999,
          objectFit: 'cover',
          overflow: 'hidden'
        }}
      />
      <h1>would you like an escape?</h1>
      <div className='buttons'>
        <button onClick={() => handleOptionClick('Yes')}>
          yes
        </button>
        <button onClick={() => handleOptionClick('No')}>
          no
        </button>
      </div>
    </div>
  );
};

export default EscapeQuestion;
