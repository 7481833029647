import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import hoverSound from './music/hover2.wav';
import oob2 from './images/angel.gif';
import teaserVideo from './videos/contagious_vid.mp4';
import teaserVideoSmall from './videos/contagious_small.mp4';
import overlay from './images/overlay_large.png';
import glitchVideo from './videos/glitch_purple.mp4';
import purpleCa from './images/purple_ca.PNG';
import { applyAccentColorFromStorage } from './utils/theme';


const AnimationWithImages = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const navigate = useNavigate();
    const videoRef = useRef(null);

    const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

    const updateAccentColor = () => {
        document.documentElement.style.setProperty('--accent-color', '#BD00FF');
        document.documentElement.style.setProperty('--accent-glow', 'rgba(206, 0, 255, 0.8)');
    };

    const handleStart = () => {
        localStorage.setItem('accent', 'purple'); // Save the setting
        applyAccentColorFromStorage();            // Apply it now
        setIsPlaying(true);
        if (videoRef.current) {
            videoRef.current.play().catch(err => console.error("Video playback failed:", err));
        }
    };


    const playHoverSound = () => {
        if (!isTouchDevice) {
            const hoverAudio = new Audio(hoverSound);
            hoverAudio.play().catch(err => console.error("Hover sound playback failed:", err));
        }
    };

    const videoSource = window.innerWidth < 768 ? teaserVideoSmall : teaserVideo;

    useEffect(() => {
        updateAccentColor();
        const video = videoRef.current;
        const handleVideoEnd = () => navigate('/');
        if (video) {
            video.addEventListener('ended', handleVideoEnd);
        }
        return () => {
            if (video) {
                video.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, [navigate]);

    return (
        <div
            className="animation-container"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                margin: 0,
                padding: 0,
                overflow: 'hidden',
                zIndex: 0
            }}
        >
            {/* Static Overlay Image */}
            <img
                src={overlay}
                alt="Overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    mixBlendMode: 'darken',
                    pointerEvents: 'none',
                    zIndex: 9999,
                    objectFit: 'cover'
                }}
            />

            {!isPlaying ? (
                <div
                    className="svg-button"
                    onClick={handleStart}
                    onMouseEnter={playHoverSound}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'inline-block',
                        cursor: 'pointer',
                        zIndex: 10
                    }}
                >
                    <img
                        src={oob2}
                        alt="Start Animation"
                        className="svg-guy2"
                    />
                    <p className='play purple'>play</p>
                </div>
            ) : (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        overflow: 'hidden'
                    }}
                >
                    {/* Teaser Video Playing in Background */}
                    <video
                        ref={videoRef}
                        src={videoSource}
                        className="animated-video"
                        autoPlay
                        playsInline
                        onEnded={() => navigate('/')}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            objectFit: 'cover',
                            zIndex: 1
                        }}
                    />

                    {/* Glitch Overlay Video */}
                    <video
                        src={glitchVideo}
                        autoPlay
                        muted
                        loop
                        playsInline
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            objectFit: 'cover',
                            mixBlendMode: 'screen',
                            zIndex: 2,
                            pointerEvents: 'none'
                        }}
                    />

                    {/* Purple Overlay Image */}
                    <img
                        src={purpleCa}
                        alt="Purple Overlay"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            objectFit: 'cover',
                            mixBlendMode: 'color-burn',
                            zIndex: 3,
                            pointerEvents: 'none'
                        }}
                    />

                    {/* Optional Glitch Text */}
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#BD00FF',
                            textShadow: '0 0 5px #BD00FF, 0 0 10px #BD00FF',
                            fontSize: '2rem',
                            zIndex: 4,
                            userSelect: 'none',
                            pointerEvents: 'none'
                        }}
                    >

                    </div>
                </div>
            )}
        </div>
    );
};

export default AnimationWithImages;

