import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import overlay from './images/overlay_large.png';

const SuccessPage = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Reset theme to red and persist it
        localStorage.setItem('accent', 'red');
        document.documentElement.style.setProperty('--accent-color', '#FF0000');
        document.documentElement.style.setProperty('--accent-glow', 'rgba(255, 0, 0, 0.8)');

        // Begin loading animation
        const loadingTimer = setTimeout(() => {
            setLoading(false);
            navigate('/animation');
        }, 10000);

        return () => clearTimeout(loadingTimer);
    }, [navigate]);

    return (
        <div>
            <img
                src={overlay}
                alt="Overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    mixBlendMode: 'darken',
                    pointerEvents: 'none',
                    zIndex: 9999,
                    objectFit: 'cover',
                    overflow: 'hidden'
                }}
            />
            {loading && (
                <div className='bar'>
                    <h1 className='loading-title' style={{ textAlign: 'left' }}>
                        recharging...
                    </h1>
                    <div className='bar-box'>
                        <div className='border-fill'></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SuccessPage;
