import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './App.css'; // Ensure to import your CSS file
import overlay from './images/overlay_large.png'; // Default import
import glitchVideo from './videos/glitch_purple.mp4'; // Import the glitch video
import purpleCa from './images/purple_ca.PNG'; // Import the new overlay image

const PasswordPage = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false); // State for error message
    const [showGlitchVideo, setShowGlitchVideo] = useState(false); // State to control glitch video overlay
    const [showOverlayImage, setShowOverlayImage] = useState(false); // State to control purple_ca overlay
    const [hideCursor, setHideCursor] = useState(false); // State to hide the cursor
    const [disableInput, setDisableInput] = useState(false); // State to disable input field
    const [glitchText, setGlitchText] = useState(false); // State to change text color
    const [titleText, setTitleText] = useState('enter your password'); // State to update title text

    const inputRef = useRef(null);
    const navigate = useNavigate(); // Initialize navigate function

    const handleInputChange = (e) => {
        if (disableInput) return; // Prevent further input changes

        // Convert the input value to lowercase and limit to 10 characters
        const newPassword = e.target.value.slice(0, 10).toLowerCase();
        setPassword(newPassword);
    
        // Check if the entered password is exactly 10 characters
        if (newPassword.length === 10) {
            if (newPassword === 'silhouette') {
                navigate('/silhouette'); // Redirect to the desired page if correct
            } 
            else if (newPassword === 'contagious') {
                navigate('/contagious');
            } 
            else {
                setError(true); // Show error if password is incorrect
            }
        } else {
            setError(false); // Hide error if less than 10 characters are typed
        }
    };

    // Automatically focus the input on load
    useEffect(() => {
        if (!disableInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [disableInput]);

    return (
        <div style={{ cursor: hideCursor ? 'none' : 'auto' }}>
            {/* Background overlay image */}
            <img
                src={overlay}
                alt="Overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    pointerEvents: 'none',
                    zIndex: 9999,
                    objectFit: 'cover',
                    overflow: 'hidden',
                    mixBlendMode: 'darken'
                }}
            />

            {/* Back button */}
            <button
                className="back-button"
                onClick={() => navigate('/')}
                style={{
                    color: glitchText ? '#DB00FF' : '',
                    textShadow: glitchText ? '0 0 5px #DB00FF, 0 0 10px #DB00FF' : ''
                }}
            >
                ←
            </button>

            {/* Password input field */}
            <div className="password-container">
                <h1
                    className="password-title"
                    style={{
                        color: glitchText ? '#DB00FF' : '',
                        textShadow: glitchText ? '0 0 5px #DB00FF, 0 0 10px #DB00FF' : ''
                    }}
                >
                    {titleText}
                </h1>
                <input
                    type="text"
                    ref={inputRef}
                    value={password}
                    onChange={handleInputChange}
                    maxLength={10}
                    className="password-input"
                    style={{
                        width: `${Math.max(password.length, 1) + 1}ch`,
                        color: glitchText ? '#DB00FF' : '',
                        textShadow: glitchText ? '0 0 5px #DB00FF, 0 0 10px #DB00FF' : '',
                        borderColor: glitchText ? '#DB00FF' : '',
                        outline: glitchText ? 'none' : '',
                    }}
                    disabled={disableInput} // Disable input when glitch starts
                />
                {error && (
                    <p
                        className="error-message"
                        style={{
                            color: glitchText ? '#DB00FF' : '',
                            textShadow: glitchText ? '0 0 5px #DB00FF, 0 0 10px #DB00FF' : ''
                        }}
                    >
                        wrong password
                    </p>
                )}
            </div>

            {/* Glitch Video Overlay (Loops Indefinitely) */}
            {showGlitchVideo && (
                <video
                    src={glitchVideo}
                    autoPlay
                    loop
                    playsInline
                    muted
                    className="glitch-video"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        objectFit: 'cover',
                        zIndex: 10000,
                        mixBlendMode: 'screen',
                        pointerEvents: 'none'
                    }}
                />
            )}

            {/* Purple Image Overlay (Color Burn Effect) */}
            {showOverlayImage && (
                <img
                    src={purpleCa}
                    alt="Purple Overlay"
                    className="purple-overlay"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        objectFit: 'cover',
                        zIndex: 10001, // Ensures it's ABOVE the glitch video
                        mixBlendMode: 'color-burn',
                        pointerEvents: 'none'
                    }}
                />
            )}
        </div>
    );
};

export default PasswordPage;
