import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import overlay from './images/overlay_large.png'; // Default import
import oob2 from './images/oobred.svg';
import oob3 from './images/oobangelpurple.svg';
import lock from './images/lock.png';




const NoPage = () => {
  const navigate = useNavigate(); // Initialize navigate function


  return (
    <div className='password-container'>
      <img
        src={overlay} // Use the overlay image
        alt="Overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw', // Ensure the overlay spans the full width of the viewport
          height: '100vh', // Ensure the overlay spans the full height of the viewport
          mixBlendMode: 'darken', // Apply darken blending mode
          pointerEvents: 'none', // Prevent interaction interference
          zIndex: 9999, // Ensure the overlay is on top
          objectFit: 'cover', // Ensure the image scales correctly without distortion
          overflow: 'hidden' // Crop the overlay to fit within the screen size
        }}
      />
      <button className='back-button' onClick={() => navigate('/')}>
        ←
      </button>
      
      <div className='levels'>
  <div className='textandicon'>
    <div className='surround'>
      <img src={oob2} alt="Start Animation" className="svg-guy3" />
    </div>
    <p className='p-red'>LEVEL 1: SILHOUETTE</p>
  </div>

  <div className='textandicon'>
    <div className='surround'>
      <img src={oob3} alt="Start Animation" className="svg-guy4" />
    </div>
    <p className='p-pink'>LEVEL 2: CONTAGIOUS</p>
  </div>

  <div className='textandicon'>
    <div className='surround'>
      <img src={lock} alt="Start Animation" className="lock-icon" />
    </div>
    <p className='p-white'>LEVEL 3: PENDING...</p>
  </div>

  <div className='textandicon'>
    <div className='surround'>
      <img src={lock} alt="Start Animation" className="lock-icon" />
    </div>
    <p className='p-white'>LEVEL 4: PENDING...</p>
  </div>
</div>



    
    </div>
  );
};

export default NoPage;
