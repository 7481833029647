import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import overlay from './images/overlay_large.png';
import glitchVideo from './videos/purple.gif';

const SuccessPage2 = () => {
    const [loading, setLoading] = useState(true);
    const [showVideo, setShowVideo] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        // Show glitch video & switch to pink after 5s
        const videoTimer = setTimeout(() => {
            setShowVideo(true);
            document.documentElement.style.setProperty('--accent-color', '#BD00FF');
            document.documentElement.style.setProperty('--accent-glow', 'rgba(206, 0, 255, 0.4)');
            localStorage.setItem('accent', 'purple');
        }, 5000);

        // Navigate after 7s
        const loadingTimer = setTimeout(() => {
            setLoading(false);
            navigate('/dialog');
        }, 7000);

        return () => {
            clearTimeout(videoTimer);
            clearTimeout(loadingTimer);
        };
    }, [navigate]);

    return (
        <div>
            <img
                src={overlay}
                alt="Overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    mixBlendMode: 'darken',
                    pointerEvents: 'none',
                    zIndex: 9999,
                    objectFit: 'cover',
                    overflow: 'hidden'
                }}
            />

            {loading && (
                <div className='bar'>
                    <h1 className='loading-title' style={{ textAlign: 'left' }}>
                        recharging...
                    </h1>
                    <div className='bar-box'>
                        <div className='border-fill-con'></div>
                    </div>
                </div>
            )}

            {showVideo && (
                <img
                    src={glitchVideo}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        objectFit: 'cover',
                        mixBlendMode: 'screen',
                        zIndex: 9998,
                        pointerEvents: 'none',
                    }}
                />
            )}
        </div>
    );
};

export default SuccessPage2;
