import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EscapeQuestion from './EscapeQuestion';
import PasswordPage from './PasswordPage';
import SuccessPage from './SuccessPage'; // Import your success page component
import AnimationWithMusic from './Animation';
import SuccessPage2 from './SuccessPage2'; 
import NoPage from './No.js'
import Animation2 from './Animation2'
import Dialog from './dialog'
import Dialog2 from './dialog2'
import Dialog3 from './dialog3'
import { useEffect } from 'react';
import { applyAccentColorFromStorage } from './utils/theme';

const App = () => {

  useEffect(() => {
    applyAccentColorFromStorage();
  }, []);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EscapeQuestion />} />
        <Route path="/password" element={<PasswordPage />} />
        <Route path="/silhouette" element={<SuccessPage />} /> {/* Define your success page route */}
        <Route path='/animation' element ={<AnimationWithMusic />} />
        <Route path='/no' element ={<NoPage />} />
        <Route path='/contagious' element ={<SuccessPage2 />} />
        <Route path='/animation2' element ={<Animation2 />} />
        <Route path='/dialog' element ={<Dialog />} />
        <Route path='/dialog2' element ={<Dialog2 />} />
        <Route path='/dialog3' element ={<Dialog3 />} />

        

      </Routes>
    </Router>
  );
};

export default App;
